import React, { useState } from 'react';
import './App.css'; // Import your CSS file for styling
import blueMoonImage from './BLU_Logo.png';
import circleImage1 from './circle1.png'; // Import your circle image file 1
import circleImage2 from './circle2.png'; // Import your circle image file 2


function App() {
  const [showCircles, setShowCircles] = useState(false);

  const toggleCircles = () => {
    setShowCircles(!showCircles);
  };
  return (
    <div className="container">
      {showCircles && ( <div className="blue-circle-2" onClick={toggleCircles}></div>)}
        {showCircles ? (
        <div className="grid-container">
          <div className="circle" style={{ backgroundImage: `url(${circleImage1})` }}>
            <div className="circle-text">Chief Executive Officer<br/><b>Jason Yang</b></div>
          </div>
          <div className="circle" style={{ backgroundImage: `url(${circleImage2})` }}>
            <div className="circle-text">Chief Creative Officer<br/><b>Helena Packer</b></div>
          </div>
        </div>
      ) : (
        <div className="blue-circle" onClick={toggleCircles}></div>
      )}
      {showCircles ? null : ( <img src={blueMoonImage} alt="Blue Moon" className="center-image" /> )}
      {showCircles ? null : ( <div className="center-text">BlueMoon AI is dedicated to developing AI toolkits tailored for the creative communities in the advertising and entertainment sectors. In an era marked by shortened timelines and economized budgets, AI-driven tools empower storytellers to maintain relevance and adaptability in the marketplace. Our mission at BlueMoon AI is to pave the way for creative expression by streamlining workflows with AI efficiencies, allowing creatives to focus on their craft. Contact us at <a href={"info@bluemoonai.com"}>info@bluemoonai.com</a></div>)}
        <footer className="footer">© 2024 Bluemoon AI. All rights reserved.</footer>
    </div>
  );
}
export default App;